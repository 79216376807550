<template>
    <div>
      <v-dialog ref="dialog" v-model="date_dialog" :return-value.sync="date" persistent width="290px" >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field outlined dense :height="10" rounded color="white" v-model="date" label="Select a day" persistent-hint :hint="hint" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" ></v-text-field>
        </template>
        <v-date-picker :events="events" event-color="green lighten-1" @change="month_change" v-model="date" scrollable color="#ffffff50">
          <v-spacer></v-spacer>
          <v-btn rounded color="white" light @click="date_dialog = false" > Cancel </v-btn>
          <v-btn rounded color="white" light @click="$refs.dialog.save(date);emit_day_select(date)"  > OK </v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'date-newpairs-select',
    components:{
    },
    emits:[
    
      'date_select'
    ],
    data(){return {
        date_dialog:false,
        date:undefined,
        events:[]
        }
    },
    props: { 
        hint:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['pairs_launched_cnt_month']),
        emit_day_select(){
          this.$emit('date_select', this.date)
      
        },
        month_change(e){
          // this.do_load_events(e)
        },
        do_load_events(date){
          const month_year = `${date.split('-')[1]}_${date.split('-')[0]}`;
          console.log(month_year);
          this.pairs_launched_cnt_month({month_year:month_year}).then(r=>{
              this.events = r.data.map( obj => {return obj.date} )
              console.log(this.events);
          })
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
      date(newV,oldV){
        this.do_load_events(newV)
      }
    },
}
</script>
<style scoped lang="scss">
</style>