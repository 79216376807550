<template>
    <div>
        <!-- {{selected.strategy}} -->
        <div v-if="user_data.info" >
            <v-container fluid class="ma-0 pa-0">
                <v-row no-gutters>
                    <v-col cols="12" md="12">
                        <v-sheet class="pa-2 ma-2 full-height" rounded="sm">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" md="12">
                                            <agent-selector 
                                            @list_selected="(s) => { select_strategy(s.id); dialog_select_strategy=false}"
                                            @create_requested="selected.strategy_id=0;dialog_strat_create= !dialog_strat_create"
                                            ></agent-selector>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <transition name="roll-down" >
                                            <v-container fluid v-if="selected.strategy.id">
                                                <v-row>
                                                    <v-col sm="12" md="3">
                                                        <agent-implementations-manage :strategy="selected.strategy" @update_strategy="do_get_strategy_list"></agent-implementations-manage>
                                                    </v-col>
                                                    <v-col cols="12" md="9">
                                                        <strategy-shower :strategyId="selected.strategy.id"></strategy-shower>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            <div v-else>
                                                <center> <i style="color:gray"> <- Please select an agent design  </i> </center>
                                            </div>
                                        </transition>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
            <!-- strat creation Title  -->
            <v-dialog v-model="dialog_strat_create"  max-width="70vw" >
                <v-card>
                    <v-system-bar color="#1b3e3830" dark >
                        <v-spacer></v-spacer>
                        <v-icon>mdi-window-minimize</v-icon>
                        <v-icon>mdi-window-maximize</v-icon>
                        <v-icon @click="dialog_strat_create=false" >mdi-close</v-icon>
                    </v-system-bar>

                    <v-card-title>
                        <span class="text-h5">New Agent</span>
                    </v-card-title>
                    <v-card-text>
                        <builder-strategy-create :strategyId="selected.strategy.id" @saved="update_strategies">
                        </builder-strategy-create>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="white darken-1" text @click="dialog_strat_create = false" > Close </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- streams popup  -->
            <!-- <v-dialog v-model="dialog_streams"  max-width="70vw" >
                <v-card>
                    <v-btn color="white darken-1" style="float:right" text @click="dialog_strat_create = false" > <v-icon small @click="tab_index=1">mdi-close</v-icon> </v-btn>
                    <v-card-title>
                        <h2 > Select Pair Streams</h2>
                    </v-card-title>
                    <v-card-text>
                        <app-pair-input-streams @selected="dialog_streams = false"></app-pair-input-streams>
                        
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog> -->

            
            <v-dialog v-model="dialog_select_strategy" max-width="70vw" >
                        <v-sheet class="pa-2" rounded="sm" >

                            <h2>Please select an Agent <v-icon class="ma-0">mdi-robot</v-icon></h2>
                            <v-menu v-model="contextMenuOpen" :position-x="contextMenuX" :position-y="contextMenuY">
                                <v-list>
                                    <v-list-item @click="handleMenuItemClick('Option 2')">Settings</v-list-item>
                                    <v-list-item @click="dialog_strat_create = !dialog_strat_create">Edit</v-list-item>
                                    <v-list-item @click="handleMenuItemClick('Option 1')">Delete</v-list-item>
                                </v-list>
                            </v-menu>
                    
                            <agent-selector @list_selected="(s) => { select_strategy(s.id); dialog_select_strategy=false} "></agent-selector>
                            
                            <!-- <div v-for="strategy in strategies" :key="strategy.id" >     
                                <v-btn 
                                @contextmenu.prevent="selected.strategy = strategy; showContextMenu($event, strategy.id)" 
                                style="display:flex;justify-content:space-between"
                                class="mb-1" elevation="2" 
                                :color="selected.strategy == strategy? 'red' : 'default' "  
                                small 
                                block 
                                @click="select_strategy(strategy.id); dialog_select_strategy=false" >  
                                    <div> {{strategy.name}} </div>
                                    <div>
                                        <v-icon small @click="tab_index=0">mdi-strategy</v-icon>
                                        <v-icon small @click="tab_index=1">mdi-rocket</v-icon>
                                    </div>
                                </v-btn>
                            </div> -->

                            <!-- <v-btn class="mb-1" elevation="2" color="white" small light outlined block @click="selected.strategy_id=0;dialog_strat_create= !dialog_strat_create" > <v-icon class="ma-0">mdi-plus</v-icon></v-btn> -->
                            <!-- <v-btn class="mb-2" elevation="2" color="pink" small  block @click="dialog_streams= !dialog_streams" > streams </v-btn> -->

                        </v-sheet>
            </v-dialog>
            
        </div>
        <div v-else>
            <view-login></view-login>
        </div>
    </div>
</template>
<script>
import { mapState,mapActions } from 'vuex'
// import BuilderCondition from '@/components/builder-condition.vue'

import AgentImplementationsManage from '@/components/agent-implementations-manage.vue'
import GeneralGameCard            from '@/components/general-game-card.vue'
import AppPairInputStreams        from '@/components/app-pair-input-streams.vue'
import PleaseLogIn                from '@/components/view-login.vue'
import SelectorMetric             from '@/components/selector-pair-metric.vue'
import BuilderStrategyCreate      from '@/components/agents/builder-strategy-create.vue'
import StrategyShower             from '@/components/agents/strategy-shower.vue'
import StrategySimulatorSmall     from '@/components/agents/strategy-simulator-small.vue'
import StrategySimulator          from '@/components/agents/strategy-simulator.vue'
import AgentSelector              from '@/components/agents/agent-selector.vue'

export default {
    name: 'agents-view',
    components:{
        StrategyShower,
        PleaseLogIn,
        StrategySimulator,
        GeneralGameCard,
        SelectorMetric,
        AppPairInputStreams,
        // BuilderCondition,
        BuilderStrategyCreate,
        StrategySimulatorSmall,
        AgentImplementationsManage,
        AgentSelector
    },
    emits:[ ],
        
    data(){
        
        return {

        contextMenuOpen: false,
        contextMenuX: 0,
        contextMenuY: 0,
        selectedOption: null,

        strategies:undefined,
        metrics:undefined,
        dialog_strat_create:false,
        // dialog_streams: false,
        dialog_select_strategy:false,
        show_agent_select_list:false,
        info_backtest:{
            pair_ids:"1257",
            poll_interval:60,
            max_duration:60*60*6,
            strategy_id:0,
        },
        tab_index:0,
        selected:{
            strategy_id:0,
            strategy:{'name':'',id:0},
            pairs:[],
        }
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_strategy_list()


    },
    methods:{
        ...mapActions(['get_strategies_list','docs_metrics_all','get_user_info', 'backtest_pair']),
        showContextMenu(event, agent_id) {
            event.preventDefault();
            this.contextMenuX = event.clientX;
            this.contextMenuY = event.clientY;
            this.contextMenuOpen = true;
        },
        handleMenuItemClick(option, data) {
            this.selectedOption = option;
            this.contextMenuOpen = false;

        },

        do_get_strategy_list(){
            this.get_strategies_list().then((r)=>{
                this.strategies = r.data
                console.log(this.selected.strategy.id);
                if (this.selected.strategy.id){ this.selected.strategy = this.strategies.filter(x=>x.id == this.selected.strategy.id)[0]}

                // getting sid from url 
                const urlSearchParams = new URLSearchParams(window.location.search);
                const paramName = 'sid'; 
                if (urlSearchParams.has(paramName)) {
                    const paramValue = urlSearchParams.get(paramName);
            
                    this.select_strategy(paramValue)
                }else { 
                    // this.dialog_select_strategy = true;
                    this.show_agent_select_list = true; 
                } 


            })
        },

        update_strategies(){
            console.log("UPDATE STRATS RAN");
            this.dialog_strat_create = false
            this.do_get_strategy_list()
        },
        do_backtest(){
            this.info_backtest.strategy_id = this.selected.strategy.id
            this.backtest_pair(this.info_backtest).then(r=>{
                this.do_get_strategy_list()
            })

        },
        epoch_to_string(epoch){
              const date = new Date(epoch * 1000);
              // Extract day, month, hours, and minutes from the date object
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const year = String(date.getFullYear())
              const hours = String(date.getHours()).padStart(2, '0');
              const minutes = String(date.getMinutes()).padStart(2, '0');
              return `${day}-${month} ${hours}:${minutes}`;
        },
        select_strategy(strategy_id){
            strategy_id = parseInt(strategy_id)
            for (let i = 0; i < this.strategies.length; i++) {
                const s = this.strategies[i];
                if (s.id == strategy_id){
                    this.selected.strategy = s;
                    const currentURL = new URL(window.location.href);
                    currentURL.searchParams.set('sid', s.id);
                    window.history.replaceState({}, '', currentURL.href);
                    return
                }
                
            }
            console.log(`strategy id ${strategy_id} was not found in strategies`);
            this.dialog_select_strategy = true;

        }

    },
    computed:{
        ...mapState(['user_data']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.strategy-card{
    background: linear-gradient(45deg, #00445f60, #444b634b);
}
td{
    white-space: nowrap;
}
.implementation-wrap{
    overflow: scroll;
}
.full-height{
    height:calc(100vh - 20px);
    overflow-y:scroll;
}
</style>