<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    
                </v-col>
                <v-col cols="12" md="12">
                    <v-select v-model="selected_domain" :items="['pair','trade','trader','oracles.eth','oracles.economic','oracles.general','oracles.telegram','oracles.fiat']" label="selected_domain" dense rounded solo hide-details >
                        <template v-slot:selection="{ item, index }">
                            <div style="text-transform:capitalize"> {{item}} </div>
                            </template>
                        <template v-slot:item="{ item, index }">
                            <div style="text-transform:capitalize"> {{item}} </div>
                        </template>
                    </v-select>

                    <selector-pair-metric :domain="selected_domain" @selected="(e)=>{selected_metric = e}"></selector-pair-metric>
                </v-col>
                <v-col v-if="selected_metric" sm="12" md="12">
                    <div v-if="selected_metric.args_spec.length != 0 ">
                        <args-spec-fields :argsSpec="selected_metric.args_spec" @changed="changed_args_spec_val"></args-spec-fields>
                    </div>
                    <v-btn class="ma-0"  elevation="2" small light block @click="get_metric_data" >get metric data</v-btn>
                  
                </v-col>
                <v-col sm="12" md="12" v-if="metric_data">
                    <h4>Results</h4>
                    <table>
                        <tr v-for="(val, name) in metric_data" :key="name">
                            <th>{{name}}</th>
                            <td>{{val}}</td>
                        </tr>
                    </table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import SelectorPairMetric from '@/components/selector-pair-metric.vue'
import ArgsSpecFields from '@/components/dynamic_lists/args-spec-fields.vue'
export default {
    name: 'single-metric-calculator',
    components:{
        SelectorPairMetric,
        ArgsSpecFields
    },
    emits:[
    ],
    data(){return {
        selected_metric:undefined,
        selected_domain:'pair',
        metric_data:undefined,

        }
    },
    props: { 
        pairId:{type:Number,default:0},
        ipId:{type:Number,default:0},
        traderId:{type:Number,default:0},
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['single_metric_calculator']),
        changed_args_spec_val(arg){
            console.log("Changed args spec", arg);
            this.selected_metric.args_spec.forEach(arg_spec => {
                if (arg_spec.name == arg.name) {
                    console.log("Matched with args", arg.name, arg_spec.name);
                    arg_spec.value = arg.value
                    
                }
                
            });
        },
        get_metric_data(){
            
            var query = {
                obj_id:null,
                args_spec:this.selected_metric.args_spec,
                strategy_metric_id:this.selected_metric.id
            }
            if (this.selected_domain == 'pair'){ query.obj_id = this.pairId}
            if (this.selected_domain == 'trade'){ query.obj_id = this.ipId}
            if (this.selected_domain == 'trader'){ query.obj_id = this.traderId}
            
            console.log("Sending query ", query);
            this.single_metric_calculator({qs:{},data:query}).then((r)=>{
                this.metric_data = r.data
            })
            

        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>