import { render, staticRenderFns } from "./backtest-pair-result.vue?vue&type=template&id=d65ba248&scoped=true&"
import script from "./backtest-pair-result.vue?vue&type=script&lang=js&"
export * from "./backtest-pair-result.vue?vue&type=script&lang=js&"
import style0 from "./backtest-pair-result.vue?vue&type=style&index=0&id=d65ba248&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d65ba248",
  null
  
)

export default component.exports