<template>
    <div>
        <v-container fluid v-if="settingsData">
            <v-row>
                <v-col cols="12" md="12">
                    <h1>{{settingsData.name}}</h1>

                </v-col>
                <v-col sm="12" md="12"> 
                    <v-text-field label="Name" rounded color="white" v-model="settingsData.name" outlined dense ></v-text-field>
                </v-col>
                <v-col sm="12" md="6"> 
                  
                    <v-select color="white" 
                        v-model="settingsData.wallet" 
                        :items="user_data.info.selected_team.wallets.filter(x=>x.network == settingsData.network)" 
                        :hint="  settingsData.wallet.address + ` [${settingsData.wallet.network}] `" 
                        persistent-hint dense rounded solo label="Wallet" 
                        :prepend-icon="selected_wallet.id ?  'mdi-pencil':''" 
                        @click:prepend="wallet_settings_dialog = true" 
                        >

                        <template v-slot:selection="{ item, index }">
                           <v-icon class="mr-1" small >mdi-wallet</v-icon> {{item.name}}
                        </template>
                        <template v-slot:item="{ item, index }">
                            <v-icon class="mr-1" small >mdi-wallet</v-icon>  {{item.name}}
                        </template> 
                    </v-select>
                </v-col>
                <v-col sm="12" md="6">
                    <v-select v-model="settingsData.network" :items="['eth.mainnet','eth.internal','eth.sim','arbritrum','avax']" label="Network" dense rounded solo hide-details > </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" md="12">
                    <h5>Action Deadline</h5>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field label="Hrs"  @change="set_action_deadline()" type="number" color="white" v-model="action_deadline.hrs" outlined dense ></v-text-field>
                                
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field label="Mins" @change="set_action_deadline()" type="number" color="white" v-model="action_deadline.mins" outlined dense ></v-text-field>
                                
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field label="Sec"  @change="set_action_deadline()" type="number" color="white" v-model="action_deadline.secs" outlined dense ></v-text-field>
                                
                            </v-col>
                        </v-row>
                    </v-container>
                    <!-- <v-text-field label="Action Deadline Seconds"  color="white" v-model="settingsData.action_deadline_interval_seconds" outlined dense ></v-text-field> -->
                </v-col>
                <v-col sm="12" md="6">
                    <!-- <v-text-field label="Gas limit slack Pct" rounded color="white" v-model="settingsData.gas_limit_slack_pct" outlined dense ></v-text-field> -->
                    <v-subheader class="pl-0"> Gas limit slack Pct </v-subheader>
                    <v-slider v-model="settingsData.gas_limit_slack_pct" :thumb-size="30" thumb-color="#ffffff00" thumb-label="always" :min="0" :max="100" >
                        <template v-slot:thumb-label="{ value }">
                            <div style=" font-size:0.9em;white-space:nowrap;margin-top:30px;"> + {{value}} % </div>
                        </template>
                    </v-slider>
                </v-col>
                <v-col sm="12" md="6">
                    <!-- <v-text-field label="Gas Price Percentile" rounded color="white" v-model="settingsData.gas_price_percentile" outlined dense ></v-text-field> -->
                    <v-subheader class="pl-0"> Gas Price Percentile </v-subheader>
                    <v-slider v-model="settingsData.gas_price_percentile" :thumb-size="30" thumb-color="#ffffff00" thumb-label="always" :min="0" :max="100" >
                        <template v-slot:thumb-label="{ value }">
                            <div style=" font-size:0.9em;white-space:nowrap;margin-top:30px;"> {{value}} % </div>
                        </template>
                    </v-slider>
                </v-col>
            </v-row>
            <v-row>

                <v-col sm="12" md="12">
                    <h5>status</h5>
                    <v-select v-model="settingsData.status" :items="['on','off','archived']" label="Status" dense rounded solo hide-details >
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" md="6" v-for="(ad, a) in settingsData.balance_range_wallet_for_actions" :key="a">
                    <h5>allowed range {{a}} </h5>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="2">
                                <input v-model="settingsData.balance_range_wallet_for_actions[a][0]" >
                            </v-col>
                            <v-col cols="12" md="8">
                                
                                <v-range-slider v-model="settingsData.balance_range_wallet_for_actions[a]" :min="0" :max="100" step="0.1"></v-range-slider>
                            </v-col>
                            <v-col cols="12" md="2">
                                <input v-model="settingsData.balance_range_wallet_for_actions[a][1]" >
                                
                            </v-col>
                        </v-row>
                    </v-container>
                    

        
                    
                </v-col>
                <v-col sm="12" md="12" align-self="center"> 
                    <div style="display:flex; justify-content: center;">
                        <v-btn light small class="mt-2" :href="`${db_url}admin/core_trader/strategyimplementation/${settingsData.id}/change/`" target="blank" ><v-icon class="pa-1">mdi-database</v-icon> Dev</v-btn>
                        <v-btn color="yellow darken-3" class="ma-2" small @click="reset_implementation" ><v-icon class="pa-1">mdi-refresh</v-icon> Reset Data</v-btn>
                        <v-btn color="red darken-3" class="mt-2" small @click="delete_implementation" ><v-icon class="pa-1">mdi-trash-can</v-icon> delete</v-btn>
                    </div>
                </v-col>
                <v-col sm="12" md="12">
                    <v-btn class="ma-0"  elevation="2" small light block @click="update_implementation" >save</v-btn>           
                </v-col>

            </v-row>
                                <!-- <pre> {{settingsData}} </pre> -->
        </v-container>
        <v-dialog v-model="wallet_settings_dialog"  max-width="70vw" >
            <v-sheet rounded="sm" v-if="selected_wallet.id">
                <wallet-settings :walletId="selected_wallet.id"></wallet-settings>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import WalletSettings from '../wallet-settings.vue'
export default {
    name: 'agent-instance-settings',
    components:{
        WalletSettings
    },
    emits:[
    ],
    data(){return {
        selected_wallet:{address:'select a wallet..'},
        wallet_settings_dialog:false,
        action_deadline:{
            hrs:0,
            mins:0,
            secs:0
        }
        }
    },
    props: { 
        //propName:{type:String,default:''},
        settingsData:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.action_deadline.hrs = Math.floor(this.settingsData.action_deadline_interval_seconds / (60*60))
        this.action_deadline.mins = Math.floor(this.settingsData.action_deadline_interval_seconds / 60)
        this.action_deadline.secs = Math.floor(this.settingsData.action_deadline_interval_seconds % 60)
        if(this.settingsData.wallet){ this.selected_wallet = this.settingsData.wallet}
    
    },
    methods:{
        ...mapActions(['strategy_implementation_action']),
        reset_implementation(){
            this.strategy_implementation_action({qs:{action:'reset'},data:this.settingsData}).then((r)=>{r})
            this.edit_implementation_dialog = false;
            this.$emit('reset_strategy')
            this.$emit('update_strategy')
        },
        update_implementation(){
            // this.selected_implementation.status =  this.selected_implementation_on_off ? 'on':'off'
            this.strategy_implementation_action({qs:{action:'update'},data:this.settingsData}).then((r)=>{r})
            this.edit_implementation_dialog = false;
            this.$emit('update_strategy')
        },
        delete_implementation(){
            this.strategy_implementation_action({qs:{action:'delete'},data:this.settingsData}).then((r)=>{r})
            this.edit_implementation_dialog = false;
            this.$emit('update_strategy')
        },
        set_action_deadline(){
            var action_deadline = 0
            action_deadline = action_deadline + this.action_deadline.hrs * 60*60
            action_deadline = action_deadline + this.action_deadline.mins * 60
            action_deadline = action_deadline + this.action_deadline.secs
            console.log(action_deadline);
            this.settingsData.action_deadline_interval_seconds = action_deadline
        }
    },
    computed:{
        ...mapState(['user_data','db_url']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
input{
    width: 100%;
    background-color: #ffffff45;
    color: white;
    text-align: center;
    border-radius: 6px;
}
</style>