<template>
    <div>

        <h2  v-if="backtest_pair_results" >
            {{backtest_pair_results.pair.name}} - Strategy 1
        </h2>
        <v-expansion-panels>
            <v-expansion-panel >
            <v-expansion-panel-header>
                Strategy 1 {{backtest_pair_results.pair.name}} 
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-sheet class="result" v-if="backtest_pair_results">
                    <table>
                        <tr>
                            <th> Total Gas </th>
                            <th> Tokens </th>
                            <th> Base Cashed </th>
                            <th> Base Invested </th>
                            <th> Gross P/L </th>
                            <th> Net P/L </th>
                        </tr>
                        <tr>
                            <td>
                                {{ backtest_pair_results.state.total_gas_fees_base }}
                            </td>
                            <td>
                                {{ backtest_pair_results.state.tokens }}
                            </td>
                            <td>
                                {{ backtest_pair_results.state.total_base_bought }}
                            </td>
                            <td>
                                {{ backtest_pair_results.state.total_base_sold }}
                            </td>
                            <td>
                                {{ backtest_pair_results.state.total_base_bought - backtest_pair_results.state.total_base_sold }}
                            </td>
                            <td>
                                {{ backtest_pair_results.state.total_base_bought - backtest_pair_results.state.total_base_sold - backtest_pair_results.state.total_gas_fees_base }}
                            </td>
                        </tr>
                    </table>

                
                    <table>
                        <tr>
                            <th>n</th>
                            <th>time</th>
                            <th>stage</th>
                            <th>test</th>
                            <th>action</th>
                            <th>state</th>
                            <th>advance</th>
                        </tr>
                        <tr v-for="cycle in backtest_pair_results.cycles" :key="cycle.time_now">
                            <td>{{cycle.n}}</td>
                            <td>{{cycle.current_time_str}}</td>
                            <td>{{cycle.current_stage.name}}</td>
                            <td>
                                
                                <table>
                                    <tr v-for="trigger_action in cycle.trigger_actions" :key="trigger_action.trigger_action.id">

                                        <td>

                                                    <table>
                                                        <tr v-for="condition in trigger_action.conditions" :key="condition.id">
                                                            <th>
                                                                <small >
                                                                {{condition.condition.condition_option.fnc_name.replaceAll('_',' ') }}
                                                                    <!-- {{condition.condition.condition_option.args_spec }} -->
                                                                    <div v-if="obj_is_empty(condition.condition.condition_option.args_spec)">

                                                                        ( <span v-for="arg,spec in condition.condition.condition_option.args_spec" :key="arg"> {{spec}} = {{arg}} ,  </span> )
                                                                    </div>
                                                                </small>
                                                            </th>
                                                            <td>

                
                                                                <table @click="view_metric_details(condition)">
                                                                    <tr v-for="evalarg in condition.condition.eval_value" :key="evalarg.arg" >
                                                                            <th :class="{
                                                                                'sucess-cell': condition.evaluate.eval_val[evalarg.arg].evaluated ,
                                                                                'fail-cell':!condition.evaluate.eval_val[evalarg.arg].evaluated}">
                                                                            <div class="test-condition">
                                                                                <sup> {{evalarg.arg.replaceAll('_',' ')}} : </sup>  
                                                                                {{condition.evaluate.eval_val[evalarg.arg].metric}} 
                                                                                {{evalarg.eval}} 
                                                                                {{evalarg.val}} ? 
                                                                                <br>
                        
                                                                            </div>
                                                                        <small> {{condition.evaluate.eval_val[evalarg.arg].evaluated}}  </small>
                                                                        </th> 
                                                                    </tr>
                                                                </table>
                                            
                                                                <!-- {{condition.evaluate}} -->
                                                            </td>

                                                        </tr>
                                                    </table>

                                        </td>
                                        <th :class="{'p-trigger-action': trigger_action.evaluated, 'n-trigger-action':!trigger_action.evaluated }" >
                                            {{trigger_action.trigger_action.action_option.fnc_name.replaceAll('_',' ')}} <br>
                                            {{trigger_action.trigger_action.action_var}} 

                                        </th>
                                    </tr>
                                </table>
                            </td>
                            <!-- action  -->
                            <td>
                                <table v-if="cycle.action">
                                    <tr>
                                        <th>action</th>
                                        <td> {{cycle.action.name}} </td>
                                    </tr>
                                    <tr>
                                        <th>tx</th>
                                        <td> {{cycle.action.tx}} </td>
                                    </tr>
                                    <tr>
                                        <th>response</th>
                                        <td> {{cycle.action.response}} </td>
                                    </tr>
                                    <tr>
                                        <th>price</th>
                                        <td> {{cycle.action.price}} </td>
                                    </tr>
                                    <tr>
                                        <th>amount_in</th>
                                        <td> {{cycle.action.amount_in}} </td>
                                    </tr>
                                    <tr>
                                        <th>amount_out</th>
                                        <td> {{cycle.action.amount_out}} </td>
                                    </tr>
                                    <tr>
                                        <th>fees</th>
                                        <td> {{cycle.action.fees}} </td>
                                    </tr>
                                </table>
                            </td>

                            <!-- state -->
                            <td>
                                <table>
                                    <tr v-for="(statemetric,metricarg) in cycle.state.start" :key="statemetric">
                                        <th>
                                            <small>  δ {{metricarg.replaceAll('_',' ')}}</small></th>
                                        <td>{{cycle.state.end[metricarg] - cycle.state.start[metricarg]}}</td>
                                    </tr>
                                </table>

                            </td>

                            <td>
                                
                                
                                <div v-if="cycle.advance.advanced">
                                    -> to {{cycle.advance.report.next_stage.name}}
                                </div>
                                <div v-else>
                                    -
                                </div>    
                            </td>


                        </tr>
                    </table>
                                        <!-- popup Title  -->
                                        <v-dialog v-model="metric_details_dialog"  max-width="50vw" >
                                            <v-card>
                                                        <v-card-title>
                                                        <span class="text-h5">popup Title</span>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <pre>

                                                        {{selected_metrics}}
                                                            </pre>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                        <v-spacer></v-spacer>
                                            
                                                        </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                </v-sheet>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>


    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'backtest-pair-result',
    components:{
    },
    emits:[
    ],
    data(){return {
        metric_details_dialog:false,
        selected_metrics:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        backtest_pair_results:{type:Object,default:Object},
    },
    mounted(){

    
    },
    methods:{
        ...mapActions([]),
        obj_is_empty(obj){
            if (Object.entries(obj).length === 0) {return false } 
            else { return true }
        },
        view_metric_details(condition){
            this.metric_details_dialog = true
            this.selected_metrics = condition.evaluate.metrics
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
td{
    white-space: nowrap;
}
tr:hover{
    background-color:rgba(255, 255, 255, 0.1);

}

.sucess-cell{
    // background-color:rgba(101, 255, 152, 0.418);
    color:white;
    border-right:3px solid green;
}
.fail-cell{
    color:#ffffff63;
}
.p-trigger-action{
    border-right:3px solid green;
}
.n-trigger-action{
    border-right:3px solid #ffffff63;
}
.result{
    overflow: scroll;
    max-height: 60vh;
}
</style>