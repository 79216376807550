<template>
    <div>
        <v-btn elevation="2" color="default" small @click="dialog= !dialog; get_ip_action_details()">{{action_id}}</v-btn>
        <!-- popup Title  -->
        <v-dialog v-model="dialog"  max-width="70vw" >
            <v-sheet rounded="sm">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            
                        </v-col>
                        <v-col cols="12" md="12" v-if="data">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header> Tx Info </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <h5>tx send</h5>
                                        <table>
                                            <tr v-for="(dat,head) in data.tx_send" :key="head">
                                                <th>{{head}}</th>
                                                <td>{{dat}}</td>
                                            </tr>
                                        </table>
                                        <h5>Reciept</h5>
                                        <pre>
                                            {{data.tx_receipt}}
                                        </pre>
                                        <h5> Tx response </h5>

                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel>
                                    <v-expansion-panel-header> Log ({{data.exec_log.length}})</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <h5>tx send</h5>
                                        <table>
                                            <tr>
                                                <th>log</th>
                                                <th>time</th>
                                                <th>data</th>
                                            </tr>
                                            <tr v-for="l in data.exec_log" :key="l.time">
                                                <td>{{Date(l.time).toString().split('GMT')[0]}}</td>
                                                <td>{{l.log}}</td>
                                                <td><pre>{{l.data}}</pre></td>
                                            </tr>
                                        </table>
                                        <h5>Reciept</h5>
                                        <pre>
                                            {{data.tx_receipt}}
                                        </pre>

                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel>
                                    <v-expansion-panel-header> Trigger Info </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <h5>Metrics Evaluated</h5>
                                        <table>
                                            <tr>
                                                <th>Metric</th>
                                                <th>Val</th>
                                                <th>Passed?</th>
                                            </tr>
                                            <tr v-for="m in data.trigger_reciept" :key="m.metric_name">
                                                <th>{{m.metric_name}}</th>
                                                <td>{{m.metrics}}</td>
                                                <td>{{m.conclusions}}</td>
                                            </tr>
                                        </table>
                                        <h5>Trigger Action</h5>
                                            <table>
                                                <tr>
                                                    <th>id</th>
                                                    <td>{{data.trigger_action.id}}</td>
                                                </tr>
                                                <tr>
                                                    <th>action name</th>
                                                    <td>{{data.trigger_action.action_option.fnc_name}}</td>
                                                </tr>
                                                <tr>
                                                    <th>action args</th>
                                                    <td>{{data.trigger_action.args_spec}}</td>
                                                </tr>
                                            </table>
                       

                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel>
                                    <v-expansion-panel-header> Balances </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <table>
                                            <tr>
                                                <th>tokens bought</th><td>{{data.tokens_bought}}</td>
                                            </tr>
                                            <tr>
                                                <th>tokens sold</th><td>{{data.tokens_sold}}</td>
                                            </tr>
                                            <tr>
                                                <th>base bought</th><td>{{data.base_bought}}</td>
                                            </tr>
                                            <tr>
                                                <th>base sold</th><td>{{data.base_sold}}</td>
                                            </tr>
                                        </table>

                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            
                        </v-col>
                    </v-row>
                </v-container>
                <!-- <pre> {{data}} </pre> -->
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'action-details',
    components:{
    },
    emits:[
    ],
    data(){return {
        dialog:false,
        action_details:undefined,
        data:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        action_id:{type:Number,default:Number},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['strategy_implementation_action']),
        get_ip_action_details(){
            // this.selected_implementation.status =  this.selected_implementation_on_off ? 'on':'off'
            this.strategy_implementation_action({qs:{action:'ip_action_details'},data:{action_id:this.action_id}}).then((r)=>{this.data = r.data.action})
            this.edit_implementation_dialog = false;
            
        },

    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>