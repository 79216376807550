<template>
<div>
    <div v-if="data">
        <v-container fluid v-if="data.situation">
            <v-row>

                <v-col cols="12" md="12">
                    <h1>Instance Situation</h1>
                    <h5>  <time-display :time="new Date(data.situation.time_now )" ></time-display> </h5>
                </v-col>

                <v-col cols="12" md="12">
                    <div class="stages-wrap">
                        <div class="stages-container">
                            <div v-for="(stage_data,stage_id) in data.situation.stages" :key="stage_id" class="stage-container">
                                <h3  v-if="stage_id == 0"> roam </h3>
                                <h3 v-else class="stage-name" @click="open_stage_dialog(stage_data)">{{stage_data.name}}  

                                    <v-icon :class="{'ma-0':1, 'rotating-cog': !stage_actions_are_blocked(stage_id)}" small  >mdi-cog</v-icon>
                                </h3>
                                <div class="stage-pair-count">{{data.situation.stage_pairs[stage_id].length}} pairs</div>
                                    
                                <div v-if="data.situation.stage_pairs[stage_id].length != 0">
                                    <div class="pair-row" v-for="pair_id in data.situation.stage_pairs[stage_id]" :key="pair_id"> 

                                        <!-- <v-btn fab class="ma-0"  elevation="2" x-small @click="" :disabled="data.situation.pairs[pair_id].actions.length == 0">   </v-btn> -->
                                        <pair-link-to-page :pair="data.situation.pairs[pair_id].pair"></pair-link-to-page>
                                        <v-icon  class="ma-0" :color="data.situation.pairs[pair_id].actions.length == 0 ? `grey`:`white` " @click="open_ip_dialog(data.situation.pairs[pair_id])">mdi-magnify</v-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>

                <v-col sm="12" md="12">
                    <v-btn class="ma-1" elevation="2" small @click="$emit('refresh_data', data)" >
                        <v-icon class="ma-0">mdi-refresh</v-icon> refresh data
                    </v-btn> 

                    <!-- <pre> {{data}} </pre> -->
                    
                </v-col>

            </v-row>
        </v-container>


        <v-dialog v-model="ip_dialog"  max-width="90vw" >
            <v-sheet rounded="sm" v-if="selected_ip">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h3> Details for pair [ {{selected_ip.pair.symbol}} ] </h3>
                        </v-col>
                        <v-col cols="12" md="12">
                        <v-tabs centered color="white" >
                                <v-tab>Actions</v-tab>
                                <v-tab>Settings</v-tab>
                                <v-tab>Metrics</v-tab>
                                <v-tab-item>
                                    <h4 class="mt-3" > Transactions </h4>
                                    <table>
                                        <tr>
                                            <th>id</th>
                                            <th>fnc</th>
                                            <th>status</th>
                                            <th>hash</th>
                                            <th>time</th>
                                        </tr>
                                        <tr v-for="a in selected_ip.actions" :key="a.id">
                                            <td>
                                                <!-- <a target="blank" :href="`http://134.209.91.206/admin/core_trader/strategyimplementationaction/${a.id}/change/`"> -->
                                                <action-details :action_id="a.id"></action-details>
                                                <!-- </a> -->
                                            </td>
                                            <td>{{a.trigger_action_option_fnc_name}}</td>
                                            <td>{{a.exec_status}}</td>
                                            <td>
                                                <a :href="`https://etherscan.io/tx/${a.tx_hash}`" target="blank">
                                                    {{a.tx_hash}}
                                                </a>
                                            </td>
                                            <td>
                                                <time-display :time="a.time_epoch" ></time-display>
                                            </td>
                                        </tr>
                                    </table>
                                </v-tab-item>
                                <v-tab-item>
                                    <!-- settings -->
                                    <v-col sm="12" md="12">
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="red" class="ma-1" small dark v-bind="attrs" v-on="on" > move to stage <v-icon class="ml-1" x-small >mdi-triangle-down</v-icon></v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item link v-for="(stage_data, stage_id) in data.situation.stages" :key="stage_id" >
                                                    <v-list-item-title v-if="stage_id != '0' " @click="do_move_ips_to_stage([selected_ip.id], stage_id)"> to {{ stage_data.name }}</v-list-item-title>
                                                    <v-list-item-title v-else @click="do_move_ips_to_stage([selected_ip.id], 0)"> ROAM </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-btn class="ma-0"  elevation="2" small   @click="update_ip_balances_from_actions" >Update Balances From Actions</v-btn>
                                        {{selected_ip}}
                                        
                                    </v-col>
                                </v-tab-item>
                                <v-tab-item>
                                    <single-metric-calculator :pairId="selected_ip.pair.id" :ipId="selected_ip.id"></single-metric-calculator>
   
                                </v-tab-item>
                        </v-tabs>

                        </v-col>
                    </v-row>
                </v-container>


            </v-sheet>
        </v-dialog>

        <v-dialog v-model="stage_dialog"  max-width="70vw" >
            <v-sheet rounded="sm" v-if="selected_stage">
                <v-container fluid>

                    <v-row>
                        <v-col cols="12" md="12">
                            <h2> [  {{selected_stage.name}} ] Settings</h2>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="green" class="ma-1" small dark v-bind="attrs" v-on="on" > move all pairs to stage <v-icon class="ml-1" x-small >mdi-triangle-down</v-icon></v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item link v-for="(stage_data, stage_id) in data.situation.stages" :key="stage_id" >
                                        <v-list-item-title v-if="stage_id != '0' " @click="do_move_ips_to_stage(data.situation.stage_pairs[selected_stage.id], stage_id)"> to {{ stage_data.name }}</v-list-item-title>
                                        <v-list-item-title v-else @click="do_move_ips_to_stage(data.situation.stage_pairs[selected_stage.id], 0)"> ROAM </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <!-- aaaaaaaaaaaaaaaaaaaaa -->
                                 <v-btn color="green" class="ma-1" small dark  elevation="2"  @click="import_token_instance_dialog = !import_token_instance_dialog"  >import token by tx</v-btn>
                                 <v-btn color="green" class="ma-1"   elevation="2" small  >move all pairs to stage</v-btn>
                        </v-col>

                        <v-col cols="12" md="12">
                                    <div v-if="stage_actions_are_blocked(selected_stage.id)" class="stage-status">
                                            <h3>STAGE ACTIONS ARE CURRENTLY PAUSED</h3>
                                            <v-icon class="ma-0" color="gray" large >mdi-pause</v-icon>
                                            <v-btn class="ma-0"  color="green" elevation="2" small   @click="unpause_stage_actions()" > 
                                                <v-icon class="ma-0">mdi-play</v-icon>
                                                Enable Stage Actions Now
                                            </v-btn>

                                        </div>
                                        <div  class="stage-status" v-else>
                                            <h3>STAGE IS LIVE</h3>
                                            <v-icon class="ma-0 rotating-cog" large >mdi-cog</v-icon>
                                            <div v-if="stage_actions_are_blocked(selected_stage.id)">
                                        <i>
                                        Stage actions are blocked. this means that the strategy is still running, but the pairs in this perticular stage wont be traded ( or actions wont be performed) even if conditions are met
                                        </i>
                                    </div>
                                    <div v-else>
                                        <i>
                                            Stage is live, actions are being send. and executed. when its not live the conditions wont trigger.
                                            the pairs will stay in the stage.
                                        </i>
                                    </div>
                                    <v-btn class="ma-0"  color="red" elevation="2" small   @click="pause_stage_actions()" > 
                                        <v-icon class="ma-0">mdi-pause</v-icon>
                                        Block Stage Actions Now
                                    </v-btn>
                                </div>
                        </v-col>


                    </v-row>

                </v-container>
            </v-sheet>
        </v-dialog>

        <v-dialog v-model="import_token_instance_dialog"  max-width="70vw" >
            <v-sheet rounded="sm" v-if="data.implementation_id">
                <div v-if="selected_stage">

                <agent-import-token-as-ip @imported="import_token_instance_dialog = false" :implementationId="data.implementation_id" :stageId="selected_stage.id"></agent-import-token-as-ip>
                </div>
                
            </v-sheet>
        </v-dialog>

    </div>
    <div v-else>
         <v-progress-circular indeterminate color="white" ></v-progress-circular>
    </div>
</div>
</template>
<script>
// Begin Component Imports
import PairLinkToPage from '../pair-link-to-page.vue'
import actionDetails from './action-details.vue'
import SingleMetricCalculator from '../dynamic_lists/single-metric-calculator.vue'
import AppSwapTokenSelector from '../app_swap/app-swap-token-selector.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import AgentImportTokenAsIp from '../agent-import-token-as-ip.vue'
import TimeDisplay from '../time-display.vue'

export default {
    name: 'agent-instance-situation',
    components:{
        PairLinkToPage,
        actionDetails,
        SingleMetricCalculator,
        AppSwapTokenSelector,
        AgentImportTokenAsIp ,
        TimeDisplay  
    },
    emits:[
     'refresh_data'
    ],
    data(){return {
        ip_dialog:false,
        add_ip_pair_dialog:false,
        stage_dialog:false,
        selected_ip:undefined,
        selected_stage:undefined,
        selected_actions :[],
        selected_trade_metric:undefined,
        import_token_instance_dialog:false,
        
        }
    },
    props: { 
        //propName:{type:String,default:''},
        data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['strategy_implementation_action','model_method']),
        open_ip_dialog(ip){
            this.selected_ip = ip
            this.ip_dialog = true
            
        },
        open_stage_dialog(stage){
            this.selected_stage = stage
            this.stage_dialog = true
        },
        stage_actions_are_blocked(stage_id){
            try{stage_id = parseInt(stage_id)}catch{}
            return this.data.situation.blocked_stages_for_actions.includes(stage_id)
        },
        pause_stage_actions(){
            this.data.situation.blocked_stages_for_actions.push(this.selected_stage.id)
           this.update_implementation({blocked_stages_for_actions:  this.data.situation.blocked_stages_for_actions})
        },
        unpause_stage_actions(){
            this.data.situation.blocked_stages_for_actions =  this.data.situation.blocked_stages_for_actions.filter( (x) =>{
                return x != this.selected_stage.id
                })
            this.update_implementation({blocked_stages_for_actions:  this.data.situation.blocked_stages_for_actions})
         
        },
        update_implementation(updated_data){
            // this.selected_implementation.status =  this.selected_implementation_on_off ? 'on':'off'
            this.strategy_implementation_action({qs:{action:'update'},data:{id:this.data.implementation_id,...updated_data}}).then((r)=>{r})
            this.edit_implementation_dialog = false;
            
        },
        do_move_ips_to_stage(implementation_pair_ips_ids, target_stage_id){
            this.strategy_implementation_action({qs:{action:'move_ips_to_stage'},data:{implementation_pair_ips_ids, target_stage_id}}).then((r)=>{
                this.implementation_situation = r.data
                if (r.status =='success'){
                    this.$emit('refresh_data',this.data)
                }
            })
        },
        update_ip_balances_from_actions(ip_id){
            // update_totals_from_actions
            this.model_method({model:'StrategyImplementationPair',method:'update_totals_from_actions',obj_id:this.selected_ip.id}).then(r=>{
                r
            })
        },
        import_token_as_instance_to_stage(token){

        }


    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.stages-wrap{
    height:70vh;
    overflow: scroll;
    .stages-container{
        display:flex;
        .stage-container{
            width: 231px;
            background-color: rgba(0, 0, 0, 0.437);
            margin: 5px;
            padding: 5px;
            max-height: 70vh;
            overflow-y: scroll;
            border-radius:5px;
            .pair-row{
                display:flex;
                justify-content: space-between;
            }
        }
    }
            .stage-name:hover{
                background:white;
                color:black;
            }
            .stage-pair-count{
                color:gray;
                font-size:0.7em;
                text-align:center;
            }

}
.rotating-cog{
    //animation: rotateCog 3s linear infinite;
    color:rgb(80, 246, 130);
}
@keyframes rotateCog {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.stage-status{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20vh;
}

</style>

