<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-container fluid>
                        <v-row>
                            <!-- day pair data  -->
                            <v-col cols="12" md="12">
                                
                            <v-stepper vertical color="red" v-model="stepper_step">

                              <v-stepper-items>
                                <v-stepper-step color="secondary" :complete="stepper_step > 1" step="1" > Backtest Scope </v-stepper-step>
                                <v-stepper-content step="1">
                                    <date-newpairs-select @date_select="list_day_pairs" ></date-newpairs-select>
                                    <v-btn color="white" light @click="stepper_step = 2" > Next </v-btn> 
                                    <table v-if="pairs">
                                        {{pairs.length}} Pairs
                                        <tr>
                                                <th>id</th>
                                                <th>name</th>
                                                <th>symbol</th>
                                                <th>price</th>
                                                <th>address</th>
                                                <!-- <th>sym</th> -->
                                        </tr>

                                        <tr v-for="p in pairs" :key="p.id">
                                            <td>{{p.id}}</td>
                                            <td>{{p.name.slice(0,20)}}</td>
                                            <td>{{p.symbol}}</td>
                                            <td><v-sparkline  :smooth="16" :gradient="['#1feaea', '#ffd200','#f72047'  ]" :line-width="3" :value="p.prices.liq_list" auto-draw stroke-linecap="round" ></v-sparkline></td>
                                            <td>{{p.currency_address.slice(1,5)}}..</td>
                                  
                                        </tr>
                                    </table>
                                  
                                </v-stepper-content>

                                <v-stepper-step color="secondary" :complete="stepper_step > 2" step="2" > Run  </v-stepper-step>
                                <v-stepper-content step="2">
                                        <v-container fluid  v-if="pairs" >
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    {{pairs.length}} Pairs selected
                                                </v-col>
                                                <v-col cols="12" md="12">
                                                        {{info_backtest.implementation_name}}
                                                    
                                                </v-col>
                                                <v-col sm="12" md="12">
                                                        <v-text-field ruonded label="Pair Ids - Comma seperated" v-model="info_backtest.pair_ids" hide-details outlined dense ></v-text-field>
                                                    
                                                </v-col>
                                                <v-col sm="12" md="6">
                                                    
                                                        <v-text-field rounded label="Poll Interval Seconds" v-model="info_backtest.poll_interval" hide-details outlined dense ></v-text-field>
                                                </v-col>
                                                <v-col sm="12" md="6">
                                                        <v-text-field rounded label="Max duration" v-model="info_backtest.max_duration" hide-details outlined dense ></v-text-field>
                                                    
                                                </v-col>
                                                <v-col sm="12" md="12">
                                                    
                                                        <v-btn :disabled="sym_btn_disabled" class="ma-0" elevation="2" color="white"  light block @click="do_backtest" >sym</v-btn>
                                                        <!-- {{test_output}} -->
                                                        <div v-if="test_output">
                                                            <!-- {{test_output.progress}} -->
                                                            <v-progress-linear class="ma-1" color="white" height="10" :value="test_output.progress.progress_pct" striped></v-progress-linear>
                                                            <v-progress-linear class="ma-1" color="white" height="10" :value="test_output.progress.progress_cycle_pct" striped></v-progress-linear>
                                                            
                                                            <table >
                                                                <tbody v-for="(pd, pair_id) in test_output.data " :key="pair_id">
                                                                    <tr >
                                                                        <th colspan="4" >
                                                                            {{pd.pair_info.name}}
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>cycle</th>
                                                                        <th>time</th>
                                                                        <th colspan="2">stage</th>
                                                                    </tr>
                                                                    <tr>

                                                                        <td> {{pd.current_state.current_cycle}} </td>
                                                                        <td> {{pd.current_state.current_time}} </td>
                                                                        <td colspan="2"> {{pd.current_state.current_stage}} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="4">
                                                                            <v-container fluid>
                                                                                <v-row>
                                                                                    <v-col cols="12" md="12">
                                                                                        
                                                                                        <table v-if="pd.trade_state">
                                                                                            <tr v-for="(val, header) in pd.trade_state" :key="header">
                                                                                                <th>{{header.replaceAll('_',' ')}}</th>
                                                                                                <td>{{val}}</td>
                                                                                            </tr>

                                                                                        </table>
                                                                                    </v-col>
                                                                               
                                                                                </v-row>
                                                                            </v-container>

                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                    

                                                            </table>
                                                        </div>
                                              
                                                </v-col>
                                            </v-row>
                                        </v-container>


                                </v-stepper-content>



                              </v-stepper-items>
                            </v-stepper>
                            </v-col>
                    
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>


async function* get_streaming(url, qs) {
    try {
        qs = new URLSearchParams(qs).toString();
        const nendpoint =  url + '?' + qs

        const response = await fetch(nendpoint); 
        const reader = response.body.getReader();
        while (true) {
                const { done, value } = await reader.read();
                if (done) { break; }
                const json_val = new TextDecoder().decode(value)
                // console.log("VAL:" , json_val);
                try{
                    const json = JSON.parse(json_val);
                    yield json;

                }catch{
                    // console.log("ERROR PARSING STREAM", json_val);
                }
        }
    } catch (error) {
        console.error('Error:', error);
    }
}


// Begin Component Imports
import DateNewpairsSelect from '../date-newpairs-select.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BacktestPairResult from '../backtest-pair-result.vue'
export default {
    name: 'strategy-simulator',
    components:{
        DateNewpairsSelect,
        BacktestPairResult
    },
    emits:[
        'nav_to_strategies'
    ],
    data
        (){return {
        pairs:[],
        backtest_pair_results:undefined,
        selected_date:'',
        selected:{
            pair:undefined,
            strategy:undefined,
        },
        stepper_step: 1,
        info_backtest:{
            pair_ids:"1257",
            poll_interval:60,
            max_duration:60*60*6,
            strategy_id:0,
            implementation_name:'Test',
        },
        test_output:undefined,
        sym_btn_disabled:false,


        
        }
    },
    props: { 
        //propName:{type:String,default:''},
        strategy:{type:Object,default:Object},
        strategies:{type:Array,default:Array},
    },
    mounted(){
        
        },
    methods:{
        ...mapActions(['pairs_launched_day','backtest_pair']),
        list_day_pairs(date){
            this.pairs_launched_day({day:date}).then(r=>{
                this.pairs = r.data
                this.info_backtest.pair_ids = this.pairs.map((x)=>x.id).join(',')
                this.selected_date = date
                this.info_backtest.implementation_name = `Test ${date}`
            })
        },
        emit_nav_to_strat(){
            this.$emit('nav_to_strategies')
            console.log("Navving to strat...");
        },
        //  do_backtest(){
        //     this.info_backtest.strategy_id = this.strategy.id
        //     this.backtest_pair(this.info_backtest).then(r=>{
        //         this.do_get_strategy_list()
        //         this.stepper_step = 3
        //     })
        // },

  
     
        async do_backtest() {
                const url = `${this.db_url}strategies/backtesting/backtest_pair_stream`
                this.info_backtest.strategy_id = this.strategy.id
                this.sym_btn_disabled = true
                const loader = get_streaming(url, this.info_backtest);
                while (true) {
                    const { value, done } = await loader.next();
                    if (done) { break; this.sym_btn_disabled=false}
                    // console.log(value);
                    this.test_output = value
                }
        }

    },
    computed:{
        ...mapState(['db_url']),
    },
    watch:{
        pairs(nv,ov){
            this.backtest_pair.pair_ids = this.pairs.map((x)=>x.id).join(',')
        }
    },
}
</script>
<style scoped lang="scss">
tbody{
    border-bottom: 3px solid white;
}
</style>