<template>
    <div>
        <!-- todo: also integrate this in builder-pair-condition -->
        <v-container fluid v-if="args_spec">
            <v-row v-for="ags in args_spec" :key="ags.name">
                <v-col cols="12" md="12">
                    <v-text-field 
                        :label="ags.name" 
                        :type="'text'"  
                        :hint="ags.example" 
                        v-model="ags.value" 
                        @change="set_arg_spec(ags)"
                        outlined 
                        dense ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'args-spec-fields',
    components:{
    },
    emits:[
        'changed'
    ],
    data(){return {
        args_spec:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        argsSpec:{type:Array,default:Array},
    },
    mounted(){
        this.args_spec = this.argsSpec
        
    },
    methods:{
        ...mapActions([]),
        set_arg_spec(arg){
            this.$emit('changed', arg)
   
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        argsSpec(nv,ov){
            this.args_spec = this.argsSpec

        }
    },
}
</script>
<style scoped lang="scss">
</style>