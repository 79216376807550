<template>
    <div>

        <!-- @click:append="create_list_dialog= !create_list_dialog" -->
        <v-autocomplete 
        v-model="selected.strategy" 
        :items="strategies" 
     
        item-title="name"  item-text="name" return-object
        prepend-inner-icon="mdi-magnify"
        append-icon="mdi-plus"
        @click:append="$emit('create_requested')"
        @change="$emit('list_selected', selected.strategy)"
        rounded
        outlined
        hide-details
        dense
        :loading="loading"
        color="white"
        :label="`Select your agent`"  >
            <template v-slot:selection="data">
                {{ data.item.name }}
            </template>
            <template v-slot:item="data">
                <v-list-item-content>
                    [ {{data.item.implementations.length}} ] 
                    {{data.item.name}}
                </v-list-item-content>
            </template>
        </v-autocomplete>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'agent-selector',
    components:{
    },
    emits:[
        'list_selected',
        'create_requested'
    ],
    data(){return {
        selected:{strategy:{id:0}},
        loading:false,
        strategies: [],
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_strategy_list()
    
    },
    methods:{
        ...mapActions(['get_strategies_list']),
        do_get_strategy_list(){
            this.get_strategies_list().then((r)=>{
                this.strategies = r.data
                console.log(this.selected.strategy.id);
                if (this.selected.strategy.id){ this.selected.strategy = this.strategies.filter(x=>x.id == this.selected.strategy.id)[0]}

                // getting sid from url 
                const urlSearchParams = new URLSearchParams(window.location.search);
                const paramName = 'sid'; 
                if (urlSearchParams.has(paramName)) {
                    const paramValue = urlSearchParams.get(paramName);
                    console.log(`Value of ${paramName}: ${paramValue}`);
                    this.select_strategy(paramValue)
                }else { this.dialog_select_strategy = true; } 


            })
        },

    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>