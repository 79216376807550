<template>
    <div>
        <v-expansion-panels>
            <v-expansion-panel v-for="i in strategy.implementations" :key="i.id" >
            <v-expansion-panel-header>
                {{i.name}}
                <v-spacer></v-spacer>
                {{i.trade_pairs.length}} Pairs Tested
                <v-spacer></v-spacer>
                ROI {{i.totals.roi.toFixed(2)}} x
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <!-- <pre>
                    {{ i }}
                </pre> -->
                <div class="implementation-wrap" v-if="i.trade_pairs">
                    <table>
                        <tr></tr>
                        <tr v-for= "(val,header) in i.totals" :key="header" >
                            <th>{{header.replaceAll('_',' ')}}</th>
                            <td>{{val}}</td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <th></th>
                            <th>name</th>
                            <th>runtime</th>
                            <th>end</th>
                            <th>gross roi</th>
                            <th>cycles</th>
                            <th>actions</th>
                        </tr>
                        <tr v-for="p in i.trade_pairs" :key="p.id">
                            <td>{{p.pair.id}}</td>
                            <td>{{p.pair.name}}</td>
                            <td style="text-align:center"> 
                                
                                {{p.state.runtime_str}} <br>
                                <small >
                                    {{epoch_to_string(p.time_start_epoch)}} -
                                    {{epoch_to_string(p.time_end_epoch)}} 
                                </small>
                            </td>
                            <td> {{p.cycles_ran}} </td>
                            <td> 
                                <span style="color:green" v-if="p.state.gross_roi > 0"> {{p.state.gross_roi.toFixed(2)}} x </span>
                                <span style="color:red" v-if="p.state.gross_roi < 0"> {{p.state.gross_roi.toFixed(3)*100}} % </span>
                                <span v-if="p.state.gross_roi == 0"> </span>

                            </td>
                            <td>
                                    <small v-if="p.actions.length == 0 "> No action</small>
                                    <div v-else>
                                        <v-btn class="ma-0" elevation="2" color="default" small block @click="p.show_actions=!p.show_actions" >Show Actions ({{p.actions.length}})</v-btn>

                                        <table v-if="p.show_actions">
                                            <tr v-for="a in p.actions" :key="a.id">
                                                <td>
                                                    {{epoch_to_string(a.time_epoch)}}
                                                </td>
                                                <td> 
                                                
                                                </td>
                                                <td>
                                                    <table>
                                                        <tr>
                                                            <th colspan="2">
                                                            {{a.trigger_reciept.identity.action_option.fnc_name.replaceAll('_',' ')}}
                                                            <small v-for="(v,k) in a.trigger_reciept.identity.args_spec" :key="k">
                                                                {{v}},
                                                            </small>

                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>tokens in</th>
                                                            <td> {{a.tokens_in}} </td>
                                                        </tr>
                                                        <tr>
                                                            <th>tokens out</th>
                                                            <td> {{a.tokens_out}} </td>
                                                        </tr>
                                                        <tr>
                                                            <th>base in</th>
                                                            <td> {{a.base_in}} </td>
                                                        </tr>
                                                        <tr>
                                                            <th>base out</th>
                                                            <td> {{a.base_out}} </td>
                                                        </tr>
                                                        <tr>
                                                            <th>base cost</th>
                                                            <td> {{a.base_cost}} </td>
                                                        </tr>
                                                    </table>
                                                    
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                            </td>
                            <td>
                                {{p.state.tokens_holding}}
                            </td>
                
                        </tr>

                    </table>
                </div>



            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'strategy-implementations-test-results',
    components:{
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        strategy:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
        epoch_to_string(epoch){
              const date = new Date(epoch * 1000);
              // Extract day, month, hours, and minutes from the date object
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const year = String(date.getFullYear())
              const hours = String(date.getHours()).padStart(2, '0');
              const minutes = String(date.getMinutes()).padStart(2, '0');
              return `${day}-${month} ${hours}:${minutes}`;
        },

    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>