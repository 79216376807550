<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <input class="amt-input" placeholder="Agent Name..." label="Agent Name" v-model="strategy.name" >
                    <!-- {{strategy}} -->
                </v-col>
                <v-col cols="12" md="12">
                    <!-- {{user_data}} -->
                    <!-- {{strategyId}}
                    {{strategy}} -->
                    <v-select v-model="strategy.team" :items="user_data.info.teams" label="Select Team" dense rounded solo hide-details >
                        <template v-slot:item="{item}">
                            <v-list-item ripple >
    
                                <v-list-item-content>
                                    {{item.name}}
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{item}">
                            <v-list-item ripple >
       
                            <v-list-item-content>
                                {{item.name}}
                            </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-select>
                </v-col>
                <v-col sm="12" md="12">
                        <v-btn class="ma-1" elevation="2" color="default" small block @click="do_create" >save</v-btn>
                        <v-btn v-if="!create_mode" color="red darken-3" x-small fab style="float:right;"  @click="do_delete" ><v-icon class="ma-0">mdi-trash-can</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'builder-strategy-create',
    components:{
    },
    emits:[
    ],
    data(){return {
        strategy:{
            id:0,
            parent:{id:null} ,
            name:'',
            tags:[],
            team :{id:0},
            

        },
        selected:{
            "team":undefined,
        },
        create_mode:true,
        }
    },
    props: { 
        strategyId:{type:Number,default:0},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        // if (this.strategyId !=0){ this.do_strat_get(this.strategyId) }
    },
    methods:{
        ...mapActions(['strats_crud_strat','model_method']),
        do_create(){
            
            if (this.strategy.team.id == 0) {console.log("Select a team!"); return }
            if (this.strategy.name == '') {console.log("input a strat name!"); return }
            this.strats_crud_strat(this.strategy).then(r=> {
                this.$emit('saved')
                console.log(r.data)
                })
        },
        do_strat_get(strategy_id){
            this.model_method({model:'Strategy',method:'obj_get', obj_id:strategy_id}).then(r=>{
                this.strategy = r.data
                this.create_mode = false
                
            })
        },
        do_delete(){
            if(confirm("are you sure you want to delete this ?")){
                this.strategy.id = -1*this.strategy.id
                this.do_create()
                this.strategy.id = 0
            }
        },
        reset(){
            this.stage = { id:0, name:'', strategy:this.strategy_id }
            this.create_mode = true
        }

    },
    computed:{
        ...mapState(['user_data']),
    },
    watch:{
        strategyId(newV, oldV){
            console.log("Strategy id changed");
            if (newV !=0){ this.do_strat_get(newV) }
        }
    },
}
</script>
<style scoped lang="scss">
</style>