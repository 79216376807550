<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-container fluid>
                        <v-row>
                            <!-- day pair data  -->
                            <v-col cols="12" md="4">
                                <date-newpairs-select @date_select="list_day_pairs" :hint="`${pairs.length} Pairs selected`"></date-newpairs-select>
                            </v-col>
                   
                            <v-col sm="12" md="4">
                                <v-btn :disabled="sym_btn_disabled" class="ma-0" elevation="2" color="white"  light block @click="do_backtest" >
                                    <v-icon class="ma-0">mdi-globe-model</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col sm="12" md="4">
                                <v-btn class="ma-0" elevation="2" color="white"  block light @click="more_dialog= !more_dialog">
                                    <v-icon class="ma-0">mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col sm="12" md="12" v-if="test_output">
                                    <div > 
                                          <v-progress-linear class="ma-1" color="white" height="10" :value="test_output.progress.progress_pct" striped></v-progress-linear>
                                          <v-progress-linear class="ma-1" color="white" height="10" :value="test_output.progress.progress_cycle_pct" striped></v-progress-linear>
                                    </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>

        <!-- popup Title  -->
        <v-dialog v-model="more_dialog"  max-width="80vw" >
            <v-sheet>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-tabs centered color="white" background-color="transparent" >
                                    <v-tab>tests</v-tab>
                                    <v-tab>wizard</v-tab>
                                    <v-tab>calendar</v-tab>
                                    <v-tab-item>
                                        <strategy-implementations-test-results :strategy="strategy"></strategy-implementations-test-results>

                                    </v-tab-item>
                                    <v-tab-item>
                                        <strategy-simulator :strategy="strategy"></strategy-simulator>
                                    </v-tab-item>
                                    <v-tab-item>
                                              <v-calendar
                                                ref="calendar"
                                                v-model="date"
                                                :weekdays="[0, 1, 2, 3, 4, 5, 6]"
                                                type="week"
                                                :events="events"
                                                event-overlap-mode="stack"
                                                :event-overlap-threshold="30"
                                                event-color="white"
                                                @change="month_change"
                                            ></v-calendar>

                                    </v-tab-item>
                            </v-tabs>
                            
                        </v-col>
                        <v-col cols="12" md="6">
                        </v-col>
                    </v-row>
                </v-container>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>


async function* get_streaming(url, qs) {
    try {
        qs = new URLSearchParams(qs).toString();
        const nendpoint =  url + '?' + qs

        const response = await fetch(nendpoint); 
        const reader = response.body.getReader();
        while (true) {
                const { done, value } = await reader.read();
                if (done) { break; }
                const json_val = new TextDecoder().decode(value)
                // console.log("VAL:" , json_val);
                try{
                    const json = JSON.parse(json_val);
                    yield json;

                }catch{
                    // console.log("ERROR PARSING STREAM", json_val);
                }
        }
    } catch (error) {
        console.error('Error:', error);
    }
}


// Begin Component Imports
import DateNewpairsSelect from '../date-newpairs-select.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BacktestPairResult from '../backtest-pair-result.vue'
import StrategySimulator from './strategy-simulator.vue';
import StrategyImplementationsTestResults from './strategy-implementations-test-results.vue';
export default {
    name: 'strategy-simulator-small',
    components:{
        DateNewpairsSelect,
        BacktestPairResult,
        StrategySimulator,
        StrategyImplementationsTestResults
    },
    emits:[
        'nav_to_strategies'
    ],
    data
        (){return {
        date:undefined,
        events:[],
            more_dialog:false,
        pairs:[],
        backtest_pair_results:undefined,
        selected_date:'',
        selected:{
            pair:undefined,
            strategy:undefined,
        },
        stepper_step: 1,
        info_backtest:{
            pair_ids:"1257",
            poll_interval:60,
            max_duration:60*60*6,
            strategy_id:0,
            implementation_name:'Test',
        },
        test_output:undefined,
        sym_btn_disabled:false,


        
        }
    },
    props: { 
        //propName:{type:String,default:''},
        strategy:{type:Object,default:Object},
        strategies:{type:Array,default:Array},
    },
    mounted(){
        
        },
    methods:{
        ...mapActions(['pairs_launched_day','backtest_pair']),
        list_day_pairs(date){
            this.pairs_launched_day({day:date}).then(r=>{
                this.pairs = r.data
                this.info_backtest.pair_ids = this.pairs.map((x)=>x.id).join(',')
                this.selected_date = date
                this.info_backtest.implementation_name = `Test ${date}`
            })
        },
        emit_nav_to_strat(){
            this.$emit('nav_to_strategies')
            console.log("Navving to strat...");
        },
        month_change(e){
          // this.do_load_events(e)
        },
        //  do_backtest(){
        //     this.info_backtest.strategy_id = this.strategy.id
        //     this.backtest_pair(this.info_backtest).then(r=>{
        //         this.do_get_strategy_list()
        //         this.stepper_step = 3
        //     })
        // },

  
     
        async do_backtest() {
                const url = `${this.db_url}strategies/backtesting/backtest_pair_stream`
                this.info_backtest.strategy_id = this.strategy.id
                this.sym_btn_disabled = true
                const loader = get_streaming(url, this.info_backtest);
                while (true) {
                    const { value, done } = await loader.next();
                    if (done) { break; this.sym_btn_disabled=false}
                    // console.log(value);
                    this.test_output = value
                }
        }

    },
    computed:{
        ...mapState(['db_url']),
    },
    watch:{
        pairs(nv,ov){
            this.backtest_pair.pair_ids = this.pairs.map((x)=>x.id).join(',')
        }
    },
}
</script>
<style scoped lang="scss">
tbody{
    border-bottom: 3px solid white;
}
</style>