<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="6">
                    <date-newpairs-select class="pa-2"  @date_select="set_start_date_test"></date-newpairs-select>
                </v-col>
                <v-col cols="12" md="6">
                    <time-interval-selector class="pa-2" label="Duration" @interval="set_interval_test"></time-interval-selector>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field class="pa-2" label="Poll Interval (Seconds)" v-model="test_setup.poll_interval" type="number" outlined dense rounded ></v-text-field>
                    
                </v-col>
                <v-col cols="12" md="6">
                    <v-btn class="ma-2"  elevation="2"  light block @click="do_backtest_stream" >Do Backtest</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="test_output">
                <v-col sm="12" md="12">
                    <h3>Status</h3>
                    <!-- {{ test_actions  }} -->
                    <table>
                        <tr v-for="a in test_actions" :key="a.id">
                            <td> {{ a.id }}</td>
                            <td> {{ a.instructions.action_name }}</td>
                            <td> {{ a.result.tokens_in }} </td>
                            <td>  <pair-link-to-page :pair="a.pair.pair"></pair-link-to-page></td>
                            <td> {{ epochToFormattedDate(a.instructions.deadline) }}</td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <th>
                                Cycle {{ test_output.info.cycle +1 }} / {{ test_output.info.total_cycles }}
                            </th>
                            <th>
                                Input Pairs {{ test_output.report.input_pairs.length }}
                            </th>
                            <th>
                                Time {{ epochToFormattedDate(test_output.report.time_now) }}
                            </th>
                        </tr>
                    </table>
                </v-col>
                <v-col sm="12" md="12">
                    <h3>Actions</h3>
                    <table v-if="test_output.actions.length" >
                        <tr>
                                        <th>Instructions</th>
                                        <th>Result</th>
                                        <th>Stage</th>
                                    </tr>
                        <tr v-for="action in test_output.actions" :key="action.id">
                            <td> 
                                <table>
                                    <tr>
                                        <th>ID</th>
                                        <td>{{ action.instructions.job_id }}</td>
                                    </tr>
                                    <tr>
                                        <th>network</th>
                                        <td>{{ action.instructions.network }}</td>
                                    </tr>
                                    <tr>
                                        <th>action</th>
                                        <td>{{ action.instructions.action_name }}</td>
                                    </tr>

                                </table>
                                <pre>
                                    {{ action.instructions }}
                                </pre>
                            </td>
                            <td>
                                IN {{ action.result.base_in }}
                                OUT {{ action.result.tokens_out }}
                                OUT {{ action.result.tx_hash }}
                                <pre>
                                    {{ action.result }}
                                </pre>
                            </td>
                            <td>
                                {{ action.advance_stage.current_stage.name }} -> {{ action.advance_stage.next_stage.name }}
                                <!-- <pre>
                                    
                                    {{ action.advance_stage }}
                                </pre> -->
                            </td>
                        </tr>
                    </table>
                    <p v-else class="text--diabled"> No actions </p>
                </v-col>

                <!-- <v-col sm="12" md="12">
                    <h3></h3>
                    <table>
                        <tr>
                            <th> Tokens </th> 
                            <td v-if="test_output.report.pls"> {{ test_output.report.pls.trade_pairs.length  }} </td>
                        </tr>
                        <tr>
                            <th>Wallet</th>
                            <td v-if="test_output.report.pls"> </td>{{ test_output.report.pls.wallet }}</td>
                        </tr>
                        <tr>
                            <th>Totals</th>
                            <td>

                                <table v-if="test_output.report.pls">
                                    <tr>
                                        <th>Costs</th>
                                        <td> <base-amount :amount="test_output.report.pls.totals.total_base_costs" ></base-amount></td>
                                    </tr>
                                    <tr>
                                        <th>Invested</th>
                                        <td> <base-amount :amount="test_output.report.pls.totals.total_base_sold" ></base-amount></td>
                                    </tr>
                                    <tr>
                                        <th>Received</th>
                                        <td> <base-amount :amount="test_output.report.pls.totals.total_base_bought" ></base-amount></td>
                                    </tr>
                                    <tr>
                                        <th>ROI</th>
                                        <td> {{ test_output.report.pls.totals.roi }} x</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    
                </v-col> -->
                <v-col sm="12" md="12">
                    <h3>Stages Status</h3>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="3" v-for="(pairs, stage) in test_output.binned_stages" :key="stage">
                                <table >
                                    <tr>
                                        <th>{{ stage }} ( x {{ pairs.length }})</th>
                                    </tr>
                                    <tr v-for="pair in pairs" :key="pair.id">
                                        <td >
                                            <pair-link-to-page :pair="pair"></pair-link-to-page>
                                        </td>
                                    </tr>
                                </table>
                            </v-col>
                   
                        </v-row>
                    </v-container>
                </v-col>

            </v-row>
        </v-container>

        
        <!-- <v-btn class="ma-0"  elevation="2" small light block @click="send_backtest_request" >send_backtest_request</v-btn> -->
        <div v-if="test_output" class="pa-2">
                    <!-- <pre> {{ test_output.report.pls }} </pre> -->
        </div>
        

        <!-- <pre>
            {{ test_output  }}
            {{ test_setup }}
            {{ implementation }}
        </pre> -->
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BaseAmount from '../base-amount.vue';
import DateNewpairsSelect from '../date-newpairs-select.vue'
import PairLinkToPage from '../pair-link-to-page.vue';
import TimeIntervalSelector from '../time-interval-selector.vue'

async function* get_streaming(url, qs) {
    try {
        qs = new URLSearchParams(qs).toString();
        const endpoint =  url + '?' + qs
        const response = await fetch(endpoint); 
        const reader = response.body.getReader();
        while (true) {
                const { done, value } = await reader.read();
                if (done) { break; }
                const json_val = new TextDecoder().decode(value)
                try{
                    const json = JSON.parse(json_val);
                    yield json;

                }catch{ console.log("ERROR PARSING STREAM", json_val); }
        }
    } catch (error) {
        console.error('Error:', error);
    }
}


export default {
    name: 'agent-implementation-test-tools',
    components:{
        DateNewpairsSelect,
        TimeIntervalSelector,
        PairLinkToPage,
        BaseAmount,
    },
    emits:[
    ],
    data(){return {
        test_output:undefined,
        test_result_expansion_panel:1,
        test_setup:{
            implementation_id:0,
            start_epoch:1692576000,
            dt:600,
            poll_interval:60
        },
        test_actions:[],
        }
    },
    props: { 
        //propName:{type:String,default:''},
        implementation:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.test_setup.implementation_id = this.implementation.id
    
    },
    methods:{
        ...mapActions(['backtest_implementation_from']),
        set_start_date_test(st){
            console.log(st);
            const dt = new Date(st)
            this.test_setup.start_epoch = dt/1000;
        },
        set_interval_test(intv){
            this.test_setup.dt = intv
        },
        send_backtest_request(){
            this.backtest_implementation_from({qs:this.test_setup,data:{}}).then((r)=>{
                console.log(r);
            })
        },
        epochToFormattedDate(epoch) {
          const date = new Date(epoch * 1000);
          // Extract day, month, hours, and minutes from the date object
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          return `${day}-${month} ${hours}:${minutes}`;
        },
        async do_backtest_stream() {
            const url = `${this.db_url}agents/implementation/backtest/stream`
            console.log(url);
            const loader = get_streaming(url, this.test_setup);
            while (true) {
                console.log(1);
                const { value, done } = await loader.next();
                console.log(2);
                if (done) { break}
                console.log("Test Output:",value);
                this.test_output = value

                for (let i = 0; i < value.actions.length; i++) {
                    const action = value.actions[i];
                    this.test_actions.push(action)
                    
                }
            }
        }

    },
    computed:{
        ...mapState(['db_url']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>