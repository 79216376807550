<template>
    <div>
        <v-container fluid>
            <v-row> 
                <v-col sm="12" md="12">
                    <v-expansion-panels>
                        <v-expansion-panel v-for="(session_type,i) in session_types" :key="i" >
                            <v-expansion-panel-header> <span style="text-transform:capitalize;"> ({{strategy.implementations.filter((s)=> {return s.type == session_type}).length }}) {{session_type}} </span> </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container fluid>
                                    <v-row>
                                        <v-col sm="12" md="12">
                                            <v-card @click="new_implementation.type=session_type; new_implementation_dialog = true" style="height:100%; display:flex; justify-content:center;" shaped outlined class="ma-1 " elevation="4" >
                                                <v-icon large class="ma-0">mdi-plus</v-icon>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="a in strategy.implementations.filter((s)=> {return s.type == session_type})" :key="a.id">
                                            <v-card shaped class="ma-1 " elevation="4">
                                                <div class="session-card">
                                                    <h5> {{a.name}}  </h5>
                                                    <v-switch  color="red" v-model="a.is_pulsed" @change="do_toggle_implementation_on_off(a)"></v-switch>
                                                    <div class="session-actions">
                                                        <v-btn v-if="a.type=='backtest'" @click="select_implementation(a)"  class="ma-1" elevation="2" :color="'default'" small  >  <v-icon class="ma-0">mdi-refresh</v-icon> </v-btn>
                                                        <v-btn @click="open_settings_dialog(a.id)"  class="ma-1" elevation="2" :color="'default'" small  >  <v-icon class="ma-0">mdi-cog</v-icon> </v-btn>
                                                        <v-btn @click="open_score_dialog(a.id)"  class="ma-1" elevation="2" :color="'default'" small  >  <v-icon class="ma-0">mdi-cash</v-icon> </v-btn>
                                                        <v-btn @click="open_situation_dialog(a.id)"  class="ma-1" elevation="2" :color="'default'" small  >  <v-icon class="ma-0">mdi-chart-areaspline</v-icon> </v-btn>
                                                    </div>
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>               
            </v-row>
        </v-container>
   
        <v-dialog v-model="new_implementation_dialog"  max-width="70vw" >
                <v-sheet>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field label="Name" v-model="new_implementation.name" color="white" rounded outlined dense ></v-text-field>
                            </v-col>
                            <v-col sm="12" md="6">
                                <v-select v-model="new_implementation.type" :items="['backtest','dryrun','live']" label="type" dense rounded solo hide-details >
                              
                                </v-select>
                                
                            </v-col>
                            <v-col cols="12" md="6">
                                <!-- {{user_data.info.selected_team.wallets}} -->
                                <v-select v-model="new_implementation_wallet" :items="user_data.info.selected_team.wallets" label="Connected Wallet" dense rounded solo hide-details >
                                    <template v-slot:selection="{ item, index }"> {{item.name}} </template>
                                    <template v-slot:item="{ item, index }"> {{item.name}} </template> 
                                </v-select>
                                <small v-if="new_implementation_wallet"> {{new_implementation_wallet.address}} </small>
                            </v-col>

                            <v-col sm="12" md="12">
                                <v-btn class="ma-1" elevation="2" color="default" small block @click="create_implementation" > Create Implementation </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-sheet>
        </v-dialog>

        <v-dialog v-if="selected_implementation" v-model="edit_implementation_dialog"  max-width="70vw" >
            <v-sheet rounded="sm">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h1>{{selected_implementation.name}}</h1>
                        </v-col>
                        <v-col v-if="! selected_implementation.is_real" sm="12" md="12">
                            <agent-implementation-test-tools :implementation="selected_implementation"></agent-implementation-test-tools>
                        </v-col>
                    </v-row>
                </v-container>
            </v-sheet>
        </v-dialog>

        
        <v-dialog v-model="dialog_settings"  max-width="90vw" >
            <v-card v-if="implementation_settings" >
                <agent-instance-settings :settingsData="implementation_settings" @update_strategy="dialog_settings=false;$emit('update_strategy')"></agent-instance-settings> 
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_score">
            <v-sheet rounded="sm" v-if="implementation_score">
                <agent-instance-score :score-data="implementation_score"></agent-instance-score>

            </v-sheet>
            <v-sheet rounded="sm" class="pa-5" v-else>
                <center>
                    <v-progress-circular indeterminate color="white" ></v-progress-circular>
                </center>
            </v-sheet>
            <v-card >
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_situation">
            <v-sheet rounded="sm" v-if="implementation_situation">
                <agent-instance-situation @refresh_data="(e)=>open_situation_dialog(e.implementation_id)" :data="implementation_situation"></agent-instance-situation>
            </v-sheet>
            <v-sheet rounded="sm" class="pa-5" style="height:80vh" v-else>
                <center>
                    <v-progress-circular indeterminate color="white" ></v-progress-circular> Loading
                </center>
            </v-sheet>
            <v-card >
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import AgentImplementationTestTools from '@/components/agents/agent-implementation-test-tools.vue'
import AgentInstanceScore from '@/components/agents/agent-instance-score.vue'
import AgentInstanceSettings from '@/components/agents/agent-instance-settings.vue'
import AgentInstanceSituation from '@/components/agents/agent-instance-situation.vue'
export default {
    name: 'agent-implementations-manage',
    components:{
        AgentImplementationTestTools,
        AgentInstanceSettings,
        AgentInstanceScore,
        AgentInstanceSituation
    },
    emits:[
        'update_strategy'
    ],
    data(){return {
        new_implementation_dialog:false,
        edit_implementation_dialog:false,
        dialog_settings:false,
        dialog_score:false,
        dialog_situation:false,
        implementation_settings:undefined,
        implementation_score:undefined,
        new_implementation:{
            name:'',
            type:'dryrun',
            is_real:false,
            strategy_id:0,

        },
        session_types: ['live','dryrun','backtest'],
        new_implementation_wallet:undefined,
        selected_implementation:undefined,
        selected_implementation_on_off:undefined,
        implementation_situation:undefined,
        
        }
    },
    props: { 
        //propName:{type:String,default:''},
        strategy:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.reset_new_implementation()
    },
    methods:{
        ...mapActions(['strategy_implementation_action', 'model_method','toggle_implementation_on_off']),
        open_settings_dialog(implementation_id){
            this.model_method({model:'StrategyImplementation', method:'settings_get', obj_id:implementation_id}).then(r=>{
                this.implementation_settings = r.data
                this.dialog_settings = true
            })

        },
        open_score_dialog(implementation_id){
            this.dialog_score = true
            this.model_method({model:'StrategyImplementation', method:'score_get', obj_id:implementation_id}).then(r=>{
                this.implementation_score = r.data
            })
        },
        open_situation_dialog(implementation_id){
         this.dialog_situation = true
         this.implementation_situation = undefined
         this.strategy_implementation_action({qs:{action:'stage_pairs_situation_get'},data:{'implementation_id':implementation_id}}).then((r)=>{
            this.implementation_situation = r.data
         })

        },
        reset_new_implementation(){
            this.new_implementation = {
                name:'',
                type:'dryrun',
                is_real:false,
                strategy_id:this.strategy.id,
            }
        },
        create_implementation(){
            // this.new_implementation.wallet_id = this.new_implementation_wallet.id
            this.strategy_implementation_action({qs:{action:'create'},data:this.new_implementation}).then((r)=>{r})
            this.new_implementation_dialog = false
            this.reset_new_implementation()
            this.$emit('update_strategy')
        },
        select_implementation(implementation){
            this.selected_implementation = implementation
            this.selected_implementation_on_off = this.selected_implementation.status == 'on' ? true : false 
            this.edit_implementation_dialog = true
        },
        do_toggle_implementation_on_off(implementation){
            this.toggle_implementation_on_off({qs:{implementation_id:implementation.id},data:{}}).then((r)=>{
                this.$emit('update_strategy')
            })
        },


    },
    computed:{
        ...mapState(['user_data']),
    },
    watch:{

        
    },
}
</script>
<style scoped lang="scss">
.implementations-list-wrap{
    display:flex;
    flex-direction: row;
}
.session-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    .session-actions:{

    }
}
</style>



