<template>
    <div>
        <v-container fluid v-if="scoreData">
            <v-row>
                <v-col cols="12" md="12">
                    <v-tabs centered color="white" >
                            <v-tab>Summary</v-tab>
      
                            <v-tab>data</v-tab>
                            <!-- summary  -->
                            <v-tab-item>
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <table>
                                                <tr> <th>Total Gross Pl</th> <td> <base-amount :amount="total_gross_pl"> </base-amount> </td> <td></td></tr>
                                                <tr> 
                                                    <th>Open Equity</th> 
                                                    <td> 
                                                        <div v-if="implementation_holdings">
                                                            
                                                            <base-amount :amount="implementation_holdings.summary.total_equity"> </base-amount> 
                                                        </div>
                                                        <!-- {{implementation_holdings}} -->
                                                    </td> 
                                                    <td>
                                                        <small v-if="implementation_holdings" >( over  {{implementation_holdings.summary.total_open}} tokens )</small>
                                                        <v-btn v-else class="ma-0"  elevation="2" small block @click="do_get_implementation_holdings" >get equity</v-btn>

                                                    </td>
                                                </tr>
                                                <tr  v-if="implementation_holdings" > <th>Total Equity</th> <td><base-amount :amount="total_gross_pl + implementation_holdings.summary.total_equity "> </base-amount> </td> <td></td></tr>
                                                <tr> <th>Total Gas Costs</th> <td> <base-amount :amount="total_costs"></base-amount></td> <td></td> </tr>
                                                <tr> <th>Total Gross roi</th> <td>{{ total_gross_roi.toFixed(2) }} x</td> <td></td></tr>
                                                <tr> <th>Total Actions</th> <td>{{ total_actions }} </td> <td></td> </tr>
                                                <tr> <th>Total pairs scanned </th> <td>{{ scoreData.pairs.length }}</td> <td></td></tr>
                                            </table>
                                            
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <h5>Actions</h5>
                                            <v-expansion-panels>
                                                <v-expansion-panel v-for="p in score_pairs" :key="p.id"  >
                                                <v-expansion-panel-header  >
                                                    <v-row no-gutters>
                                                        <v-col sm="12" md="6">
                                                            <pair-link-to-page :vlines="p.actions.map(a=>{return {time:a.time_epoch , name:a.trigger_action.action_option.fnc_name , color:'red'}})" :pair="p.pair"></pair-link-to-page>
                                                            
                                                        </v-col>
                                                        <v-col sm="12" md="3">
                                                            
                                                            <div>{{ action_buy_sell_string(p)  }}</div>
                                                        </v-col>
                                                        <v-col sm="12" md="1">
                                                            <base-amount :amount="p.state.total_base_bought - p.state.total_base_sold"></base-amount>
                                                        </v-col>
                                                        <v-col sm="12" md="1">
                                                            <div v-if="implementation_holdings"> <div style="display:flex;font-size:0.8em" v-if="implementation_holdings.holdings[p.id]">  <base-amount :amount="implementation_holdings.holdings[p.id].equity"></base-amount> </div></div>
                                                        </v-col>
                                                    </v-row>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content  >
                                                    <table>
                                                        <tr>
                                                            <th>stage</th>
                                                            <th>actions</th>
                                                            <th>pl</th>
                                                            <th>state</th>
                                                        </tr>
                                                        <tr>
                                                            <td> <div v-if="p.stage"> In {{ p.stage.name }}</div> <div v-else> Not picked up</div> </td>
                                                            <td>  {{ p.actions.length }} </td>
                                                            <td> <base-amount :amount="p.state.gross_pl"> </base-amount> </td>
                                                            <td> 
                                                                <table class="table-pair-implement">
                                                                    <tr> <th>total base sold </th><td> <base-amount :amount="p.state.total_base_sold"></base-amount> </td> </tr>
                                                                    <tr> <th>total base bought </th><td>  <base-amount :amount="p.state.total_base_bought"></base-amount>   </td> </tr>
                                                                    <tr> <th>total base costs </th><td> <base-amount :amount="p.state.total_base_costs"></base-amount> </td> </tr>
                                                                    <tr> <th>total tokens bought </th><td> {{p.state.total_tokens_bought}}</td> </tr>
                                                                    <tr> <th>total tokens sold </th><td> {{p.state.total_tokens_sold}}</td> </tr>
                                                                    <tr> <th>gross pl </th><td> <base-amount :amount="p.state.gross_pl"></base-amount> </td> </tr>
                                                                    <tr> <th>gross roi </th><td> {{p.state.gross_roi.toFixed(2)}}</td> </tr>
                                                                    <tr> <th>tokens holding </th><td> {{p.state.tokens_holding}}</td> </tr>
                                                                    <tr> <th>runtime </th><td> {{p.state.runtime_str.split('.')[0]}}</td> </tr>
                                                                </table>
                                                            </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5"> 
                                                                <v-expansion-panels>
                                                                    <v-expansion-panel v-for="a in p.actions" :key="a.id" >
                                                                    <v-expansion-panel-header>
                                                                        <div class="panel-header-pair">
                                                                            <div v-if=" a.trigger_action.action_option.fnc_name.includes('buy') "> 🟢 </div>
                                                                            <div v-else-if=" a.trigger_action.action_option.fnc_name.includes('sell') "> 🔴 </div>
                                                                            <div v-else > 🟡 </div> 
                                                                            {{ a.trigger_action.action_option.fnc_name }} 
                                                                        </div>
                                                                    </v-expansion-panel-header>
                                                                    <v-expansion-panel-content>
                                                                        <table class="table-actions">
                                                                                <tr><th>action </th><td>{{ a.trigger_action.action_option.fnc_name }} 
                                                                                    <a target="blank" :href="`${db_url}admin/core_trader/strategyimplementationaction/${a.id}/change/`"> <v-icon small class="ma-0">mdi-database</v-icon></a>
                                                                                
                                                                                </td></tr>
                                                                                <tr><th>time </th><td>{{ (a.time_epoch) }}</td></tr>
                                                                                <tr><th>tx hash </th><td>{{ a.tx_hash }}</td></tr>
                                                                                <tr><th>exec debug </th><td>{{ a.exec_debug }}</td></tr>
                                                                                <tr><th>gas used </th><td> <base-amount :amount="a.base_cost"></base-amount> </td></tr>
                                                                                <tr><th>base bought </th><td> <base-amount :amount="a.base_bought"></base-amount>  </td></tr>
                                                                                <tr><th>base sold </th><td> <base-amount :amount="a.base_sold"></base-amount>  </td></tr>
                                                                                <tr><th>tokens sold </th><td>{{ a.tokens_sold }}</td></tr>
                                                                                <tr><th>tokens bought </th><td>{{ a.tokens_bought }}</td></tr>
                                                                                <tr><th>status </th><td>{{ a.exec_status }}</td></tr>
                                                                                <tr><th>price at time </th><td>{{ a.price_at_time }}</td></tr>
                                                                                <tr>
                                                                                    <th>metric rcpt</th><td>
                                                                                    <pre>{{a.trigger_reciept}}</pre>
                                                                                </td>
                                                                                </tr>
                                                                        </table>
                                                                    </v-expansion-panel-content>
                                                                    </v-expansion-panel>
                                                                </v-expansion-panels>
                                        
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-col>
                                    </v-row>
                                </v-container>


                            </v-tab-item>

                            <v-tab-item>
                                <pre>
                                    {{ scoreData }}
                                </pre>
                            </v-tab-item>
                    </v-tabs>

                </v-col>
                <v-col cols="12" md="12">
                    
                </v-col>
            </v-row>
        </v-container>
        <!-- <pre>
            {{scoreData}}
        </pre> -->
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BaseAmount from '../base-amount.vue'
import PairLinkToPage from '../pair-link-to-page.vue'
export default {
    name: 'agent-instance-score',
    components:{
        PairLinkToPage,
        BaseAmount
    },
    emits:[
    ],
    data(){return {
        implementation_holdings:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        scoreData:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['get_implementation_holdings']),
        epochToFormattedDate(epoch) {
          const date = new Date(epoch * 1000);
          // Extract day, month, hours, and minutes from the date object
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          return `${day}-${month} ${hours}:${minutes}`;
        },
        action_buy_sell_string(implement_pair){
            var buy_sell_string = ''
            for (let i = 0; i < implement_pair.actions.length; i++) {
                const a = implement_pair.actions[i];
                if (a.trigger_action.action_option.fnc_name.includes('buy')){buy_sell_string += '🟢' }
                else if (a.trigger_action.action_option.fnc_name.includes('sell')){ buy_sell_string += '🔴'}
                else { buy_sell_string += '🟡'}
            }
            return buy_sell_string
        },
        do_get_implementation_holdings(){
            this.get_implementation_holdings({qs:{implementation_id:this.scoreData.id}}).then(r=>this.implementation_holdings = r.data)
        }
    },
    computed:{
        ...mapState(['db_url']),
        total_gross_pl(){
            return this.scoreData.pairs.map(r=>r.state.gross_pl).reduce(function (a, c) { return a + c; }, 0);
        },
        total_gross_roi(){
            return this.scoreData.pairs.map(r=>r.state.gross_roi).reduce(function (a, c) { return a + c; }, 0);
        },
        total_actions(){
            return this.scoreData.pairs.map(r=>r.actions.length).reduce(function (a, c) { return a + c; }, 0);
        },
        score_pairs (){
            return this.scoreData.pairs.filter(x=>x.actions.length)
        }, 
        total_costs(){
            return this.scoreData.pairs.map(r=>r.state.total_base_costs).reduce(function (a, c) { return a + c; }, 0);
        }   
     },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.table-actions{
    border:1px solid rgba(199, 30, 30, 0.411);
    padding:1em;
}
.table-pair-implement{
    border-bottom:solid white 1em;
}
.panel-header-pair{
    display:flex;

}
</style>